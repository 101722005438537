<template>
  <div class="rich-text-editor">
    <div
      class="rich-text-editor__editor"
      v-test:editor
      :class="{ 'rich-text-editor__editor--dragging-files': isDraggingFiles }"
      :data-drop-text="dropText"
      ref="editor"
      tabindex="0"
      v-on="dragAndDropHandlers"
      translate="no"
    />

    <div ref="toolbar" class="rich-text-editor__format-bar" v-test:formatBar>
      <div class="rich-text-editor__draft-actions--smallest-screen">
        <slot name="draftActions" />
      </div>
      <transition name="collapsible">
        <div
          id="text-format-options"
          class="text-format-options--large-screen"
          ref="textFormatOptions"
          v-test:textFormatOptions
        >
          <div class="rich-text-editor__format-bar-group">
            <ActionBarButton
              :aria-label="toggleBoldText"
              v-tooltip="toggleBoldText"
              icon="bold"
              @click="setBold"
              class="rich-text-editor__format-bar-button"
              :active="isBold"
            />

            <ActionBarButton
              :aria-label="toggleItalicText"
              v-tooltip="toggleItalicText"
              icon="italic"
              @click="setItalic"
              class="rich-text-editor__format-bar-button"
              :active="isItalic"
            />

            <ActionBarButton
              :aria-label="toggleUnderlineText"
              v-tooltip="toggleUnderlineText"
              icon="underline"
              @click="setUnderlined"
              class="rich-text-editor__format-bar-button"
              :active="isUnderlined"
            />
            <ColourPicker
              class="rich-text-editor__colour-picker"
              @setTextColour="setTextColour"
              :text-colour="textColour"
            />
          </div>

          <div
            class="rich-text-editor__format-bar-group rich-text-editor__font-settings"
          >
            <FontSettings
              :font="font"
              :font-size="fontSize"
              @setFont="setFont"
              @setFontSize="setFontSize"
            />
            <Dropdown class="dropdown--align-left">
              <template v-slot:button="{ isOpen, toggle }">
                <ActionBarButton
                  :aria-label="alignTextText"
                  v-tooltip="alignTextText"
                  :dropdown="true"
                  :active="isOpen"
                  class="rich-text-editor__format-bar-button"
                  :icon="alignmentDropdownToggleIcon"
                  @click="toggle"
                />
              </template>
              <template v-slot:content="{ toggle }" class="dropdown--left">
                <ul class="link-list" @click="toggle">
                  <li>
                    <button
                      class="button button--clean link-list__item"
                      type="button"
                      @click="setTextAlignment('left')"
                      :aria-label="alignLeftText"
                      v-tooltip="alignLeftText"
                    >
                      <Icon symbol="align-left" />
                    </button>
                  </li>
                  <li>
                    <button
                      class="button button--clean link-list__item"
                      type="button"
                      @click="setTextAlignment('center')"
                      :aria-label="alignCenterText"
                      v-tooltip="alignCenterText"
                    >
                      <Icon symbol="align-center" />
                    </button>
                  </li>
                  <li>
                    <button
                      class="button button--clean link-list__item"
                      type="button"
                      @click="setTextAlignment('right')"
                      :aria-label="alignRightText"
                      v-tooltip="alignRightText"
                    >
                      <Icon symbol="align-right" />
                    </button>
                  </li>
                </ul>
              </template>
            </Dropdown>
          </div>

          <div class="rich-text-editor__format-bar-group">
            <ActionBarButton
              :aria-label="toggleBulletListText"
              v-tooltip="toggleBulletListText"
              icon="list-bullet"
              @click="setUnorderedlist"
              :active="isUnorderedList"
              class="rich-text-editor__format-bar-button"
            />

            <ActionBarButton
              :aria-label="toggleNumberedListText"
              v-tooltip="toggleNumberedListText"
              icon="list-numbers"
              @click="setOrderedList"
              :active="isOrderedList"
              class="rich-text-editor__format-bar-button"
            />
          </div>
          <div class="rich-text-editor__format-bar-group">
            <EmojiPicker @addEmoji="addEmoji" />
            <Modal>
              <template v-slot:toggle="modal">
                <ActionBarButton
                  :aria-label="toggleLinkText"
                  v-tooltip="toggleLinkText"
                  icon="link"
                  @click="toggleLink(modal)"
                  :active="isLink"
                  class="rich-text-editor__format-bar-button"
                />
              </template>
              <template v-slot:header>
                <translate>Create link</translate>
              </template>
              <template v-slot:content="modal">
                <form @submit.prevent="insertLink(modal)" novalidate>
                  <InputField
                    type="text"
                    label="URL"
                    id="url"
                    v-model="linkUrl"
                    :focused="true"
                    :validate="validateLinkUrl"
                    :show-custom-validity="!linkUrlIsValid"
                    :validation-error-message="linkUrlErrorMessage"
                  />
                </form>
                <ModalConfirmActions
                  @cancelClicked="modal.toggle"
                  @okClicked="insertLink(modal)"
                  :ok-text="addText"
                />
              </template>
            </Modal>
            <slot name="insert-image">
              <InsertImage
                v-if="inlineImagesEnabled"
                @inlineImageInserted="onInlineImageInserted"
              />
            </slot>
          </div>
          <slot name="draftActions" />
        </div>
      </transition>

      <transition name="collapsible">
        <div
          class="text-format-options--small-screen rich-text-editor__format-bar-group"
          v-test:typographyRow
        >
          <Dropdown class="dropdown--align-left">
            <template v-slot:button="{ isOpen, toggle }">
              <ActionBarButton
                :dropdown="true"
                :active="isOpen"
                icon="bold"
                class="rich-text-editor__format-bar-button"
                @click="toggle"
                :aria-label="fontFormatOptionsText"
                v-tooltip="fontFormatOptionsText"
              />
            </template>
            <template v-slot:content="{ toggle }">
              <ul class="link-list" @click="toggle">
                <li>
                  <button
                    type="button"
                    class="button button--subtle"
                    @click="setBold"
                    :aria-label="toggleBoldText"
                    v-tooltip="toggleBoldText"
                  >
                    <Icon symbol="bold" />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="button button--subtle"
                    @click="setItalic"
                    :aria-label="toggleItalicText"
                    v-tooltip="toggleItalicText"
                  >
                    <Icon symbol="italic" />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="button button--subtle"
                    @click="setUnderlined"
                    :aria-label="toggleItalicText"
                    v-tooltip="toggleItalicText"
                  >
                    <Icon symbol="underline" />
                  </button>
                </li>
              </ul>
            </template>
          </Dropdown>
          <ColourPicker
            class="rich-text-editor__colour-picker rich-text-editor__format-bar-group"
            @setTextColour="setTextColour"
            :text-colour="textColour"
            :in-action-bar="true"
            v-test:colorPicker
          />
          <FontSettings
            :font="font"
            :font-size="fontSize"
            @setFont="setFont"
            @setFontSize="setFontSize"
          />
          <Dropdown class="hide-secondary-format-options">
            <template v-slot:button="{ isOpen, toggle }">
              <ActionBarButton
                :aria-label="alignTextText"
                v-tooltip="alignTextText"
                :dropdown="true"
                :active="isOpen"
                class="rich-text-editor__format-bar-button"
                :icon="alignmentDropdownToggleIcon"
                @click="toggle"
              />
            </template>
            <template v-slot:content="{ toggle }" class="dropdown--left">
              <ul class="link-list" @click="toggle">
                <li>
                  <button
                    type="button"
                    class="button button--clean link-list__item"
                    @click="setTextAlignment('left')"
                    :title="alignLeftText"
                  >
                    <Icon symbol="align-left" />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="button button--clean link-list__item"
                    @click="setTextAlignment('center')"
                    :title="alignCenterText"
                  >
                    <Icon symbol="align-center" />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="button button--clean link-list__item"
                    @click="setTextAlignment('right')"
                    :title="alignRightText"
                  >
                    <Icon symbol="align-right" />
                  </button>
                </li>
              </ul>
            </template>
          </Dropdown>
          <ActionBarButton
            :title="toggleNumberedListText"
            icon="list-numbers"
            @click="setOrderedList"
            :active="isOrderedList"
            class="rich-text-editor__format-bar-button hide-secondary-format-options"
          />
          <ActionBarButton
            :title="toggleBulletListText"
            icon="list-bullet"
            @click="setUnorderedlist"
            :active="isUnorderedList"
            class="rich-text-editor__format-bar-button hide-secondary-format-options"
          />
          <EmojiPicker
            @addEmoji="addEmoji"
            class="hide-secondary-format-options"
          />
          <div
            class="rich-text-editor__format-bar-group rich-text-editor__attachment-group"
          >
            <Modal>
              <template v-slot:toggle="modal">
                <ActionBarButton
                  :title="toggleLinkText"
                  icon="link"
                  @click="toggleLink(modal)"
                  :active="isLink"
                  class="rich-text-editor__format-bar-button"
                />
              </template>
              <template v-slot:header>
                <translate>Create link</translate>
              </template>
              <template v-slot:content="modal">
                <form @submit.prevent="insertLink(modal)" novalidate>
                  <InputField
                    type="text"
                    label="URL"
                    id="url"
                    v-model="linkUrl"
                    :focused="true"
                    :validate="validateLinkUrl"
                    :show-custom-validity="!linkUrlIsValid"
                    :validation-error-message="linkUrlErrorMessage"
                  />
                </form>
                <ModalConfirmActions
                  @cancelClicked="modal.toggle"
                  @okClicked="insertLink(modal)"
                  :ok-text="addText"
                />
              </template>
            </Modal>
            <slot name="insert-image">
              <InsertImage
                v-if="inlineImagesEnabled"
                @inlineImageInserted="onInlineImageInserted"
              />
            </slot>
          </div>
          <div class="rich-text-editor__draft-actions--small-screen">
            <slot name="draftActions" />
          </div>
        </div>
      </transition>
      <div class="rich-text-editor__external-actions" v-test:externalActions>
        <slot name="externalActions" />
      </div>
    </div>
  </div>
</template>

<script>
  import ActionBarButton from '@/components/ActionBarButton/ActionBarButton';
  import ColourPicker from '@/components/ColourPicker/ColourPicker';
  import Dropdown from '@/components/Dropdown/Dropdown';
  import EmojiPicker from '@/components/EmojiPicker/EmojiPicker';
  import FontSettings from '@/components/FontSettings/FontSettings';
  import Icon from '@/components/Icon/Icon';
  import InlineImage from '@/components/InlineImage/InlineImage';
  import InputField from '@/components/form/InputField';
  import InsertImage from '@/wrappers/InsertImageWrapper/InsertImageWrapper';
  import Modal from '@/components/Modal';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions';
  import Squire from 'squire-rte';
  import Vue from 'vue';
  import flattenFileTree from '@/lib/flattenFileTree';
  import { sanitizeUrl } from '@/lib/url';

  const InlineImageClass = Vue.extend(InlineImage);

  export default {
    components: {
      Icon,
      ColourPicker,
      ActionBarButton,
      InsertImage,
      InputField,
      Modal,
      ModalConfirmActions,
      EmojiPicker,
      FontSettings,
      Dropdown,
    },
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      value: {
        type: String,
        required: true,
      },
      inlineImagesEnabled: {
        type: Boolean,
        required: false,
      },
      tabindex: {
        type: [Number, String],
        required: false,
        default: 0,
      },
      activeTab: {
        type: String,
        required: false,
        default: '',
      },
      focused: {
        type: Boolean,
        required: false,
      },
      dragAndDropEnabled: {
        type: Boolean,
        required: false,
        default: true,
      },
      inlineAttachments: {
        type: Array,
        required: false,
        default: () => [],
      },
      editorPreferences: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      isDiscardingDraft: {
        type: Boolean,
        required: false,
        default: false,
      },
      previousElementFocused: {
        // . Get the previous element that was focused via props
        default: null,
      },
    },
    data() {
      return {
        editor: {},
        content: '',
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        isUnorderedList: false,
        isOrderedList: false,
        isLink: false,
        linkUrl: '',
        validateLinkUrl: false,
        isLeftAligned: true,
        isRightAligned: false,
        isCenterAligned: false,
        textAlignment: 'left',
        isDraggingFiles: false,
        inlineImages: [],
        textColour: 'rgb(59, 67, 92)',
        font: 'arial, helvetica',
        fontSize: 'medium',
      };
    },
    computed: {
      alignTextText() {
        return this.$gettext('Align text');
      },
      toggleBoldText() {
        return this.$gettext('Toggle Bold');
      },
      fontFormatOptionsText() {
        return this.$gettext('Text formatting');
      },
      toggleItalicText() {
        return this.$gettext('Toggle Italic');
      },
      toggleUnderlineText() {
        return this.$gettext('Toggle Underline');
      },
      showMoreOptionsText() {
        return this.$gettext('Show More Options');
      },
      alignLeftText() {
        return this.$gettext('Align Left');
      },
      alignCenterText() {
        return this.$gettext('Align Center');
      },
      alignRightText() {
        return this.$gettext('Align Right');
      },
      toggleBulletListText() {
        return this.$gettext('Toggle Bullet List');
      },
      toggleNumberedListText() {
        return this.$gettext('Toggle Numbered List');
      },
      dropText() {
        return this.$gettext('Drop files here');
      },
      toggleLinkText() {
        return this.isLink
          ? this.$gettext('Remove hyperlink')
          : this.$gettext('Insert hyperlink');
      },
      addText() {
        return this.$gettext('Add link');
      },
      dragAndDropHandlers() {
        return this.dragAndDropEnabled
          ? {
              dragleave: this.onDragLeave,
              drop: this.onDrop,
              dragover: this.onDragOver,
            }
          : {};
      },
      alignmentDropdownToggleIcon() {
        return this.isCenterAligned
          ? 'align-center'
          : this.isRightAligned
          ? 'align-right'
          : 'align-left';
      },
      linkUrlIsValid() {
        return Boolean(sanitizeUrl(this.linkUrl));
      },
      linkUrlErrorMessage() {
        return this.linkUrl
          ? this.$gettextInterpolate(
              this.$gettext('%{url} is not a valid URL'),
              { url: this.linkUrl }
            )
          : '';
      },
    },
    watch: {
      inlineAttachments(attachments, oldAttachments) {
        const matchInlineImageToAttachment = (attachment) => (inlineImage) => {
          return inlineImage.attachment.state === 'done'
            ? attachment.id === inlineImage.attachment.id
            : attachment.objectUrl === inlineImage.src;
        };

        // Update the inline image when the matching attachment changes.
        attachments.forEach((changedAttachment) => {
          const inlineImage = this.inlineImages.find(
            matchInlineImageToAttachment(changedAttachment)
          );
          const update = !oldAttachments.some(
            ({ id, progress }) =>
              id === changedAttachment.id &&
              progress === changedAttachment.progress
          );

          if (inlineImage && update) {
            inlineImage.src =
              changedAttachment.url || changedAttachment.objectUrl;
            inlineImage.attachment = changedAttachment;
          }
        });

        // Remove inline image when the matching attachment is removed.
        oldAttachments
          .filter(
            (oldAttachment) =>
              !attachments.find(
                ({ id, objectUrl }) =>
                  id === oldAttachment.id ||
                  objectUrl === oldAttachment.objectUrl
              )
          )
          .forEach((removedAttachment) => {
            const inlineImage = this.inlineImages.find(
              matchInlineImageToAttachment(removedAttachment)
            );
            if (inlineImage) {
              this.removeInlineImage(inlineImage);
            }
          });
      },
    },
    mounted() {
      this.textColour =
        this.editorPreferences.default_font_color || this.textColour;
      this.font = this.editorPreferences.default_font_family || this.font;
      this.fontSize = this.editorPreferences.default_font_size || this.fontSize;

      const preferedStyles = {
        color: this.textColour,
        'font-family': this.font,
        'font-size': this.fontSize,
      };
      const blockStyle = Object.entries(preferedStyles).reduce(
        (style, [key, value]) => `${style} ${key}: ${value};`,
        'word-wrap: break-word; overflow-wrap: break-word; margin: 0;'
      );

      this.editor = new Squire(this.$refs.editor, {
        blockTag: 'p',
        blockAttributes: {
          style: blockStyle,
        },
        isSetHTMLSanitized: false,
        tagAttributes: {
          ol: { style: blockStyle },
          ul: { style: blockStyle },
        },
      });

      this.editor.addEventListener('cursor', this.onCursor);
      this.editor.addEventListener('input', this.onInput);
      this.editor.addEventListener('pathChange', this.onPathChange);
      this.editor.setKeyHandler('shift-enter', this.onShiftEnter);

      // NOTE: this.content is directly put into the dom without any sanitizing and
      // therefore vulnerable to XSS attacks. The HTML should have been sanitized by
      // the API.
      this.editor.setHTML(this.value || ''); // ! THIS SQUIRE METHOD OVERRIDES THE VIEW FOCUSED ELEMENT (SQUIRE BLUR METHOD DOESN'T WORK)
      if (this.previousElementFocused) {
        this.previousElementFocused.focus(); // . Set previous focus
      }
      Array.from(this.$refs.editor.querySelectorAll('img')).forEach((el) => {
        try {
          const attachment = this.inlineAttachments.find(
            ({ content_id, url }) => {
              return (
                content_id === el.dataset.cid || url === el.attributes.src.value
              );
            }
          );

          if (attachment) {
            const src = attachment.url || attachment.objectUrl;
            const inlineImage = this.createInlineImage(src, attachment);
            el.parentNode.replaceChild(inlineImage.$el, el);
          }
        } catch (err) {
          el.parentNode.removeChild(el);
        }
      });

      Array.from(
        this.$refs.editor.querySelectorAll('[data-squire-block]')
      ).forEach((el) => {
        el.setAttribute('style', blockStyle);
        el.removeAttribute('data-squire-block');
      });

      if (this.tabindex) {
        this.$refs.editor.setAttribute('tabindex', this.tabindex);
      }

      if (this.focused) {
        this.$refs.editor.focus();
      }

      this.content = this.editor.getHTML();
    },
    destroyed() {
      this.editor.removeEventListener('input', this.onInput);
      this.editor.removeEventListener('pathChange', this.onPathChange);
      this.editor.destroy();
    },
    methods: {
      addEmoji(emoji) {
        this.editor.insertHTML(
          `<span style="font-size:${this.fontSize}">${emoji.native}</span>`
        );
        this.editor.focus();
      },
      onDragLeave() {
        this.isDraggingFiles = false;
      },
      onDragOver(e) {
        if (Array.from(e.dataTransfer.types).includes('Files')) {
          e.preventDefault();
          e.dataTransfer.dropEffect = 'copy';
          this.isDraggingFiles = true;
        }
      },
      onDrop(e) {
        if (this.isDraggingFiles) {
          e.preventDefault();
          this.isDraggingFiles = false;

          flattenFileTree(e.dataTransfer).then((files) => {
            this.$emit('dropFiles', files);
            e.dataTransfer.clearData();
          });
        }
      },
      onCursor() {
        const { family, size, color } = this.editor.getFontInfo();

        /* Squire adds 'sans-serif' font family as a fallback font family in
           addition to what we specify as font family options. So to map with
           StartMail font family option set we need to take out the last
           sans-serif option. */
        if (family && family !== 'null') {
          this.font = family.replace(/, sans-serif$/, '');
        } else {
          this.setFont(
            this.editorPreferences.default_font_family || 'arial, helvetica'
          );
        }

        if (color) {
          this.textColour = color;
        } else {
          this.setTextColour(
            this.editorPreferences.default_font_color || 'rgb(59, 67, 92)'
          );
        }

        if (size) {
          this.fontSize = size;
        } else {
          this.setFontSize(
            this.editorPreferences.default_font_size || 'medium'
          );
        }
      },
      onInput() {
        const activeInlineImagesIndices = Array.from(
          this.$refs.editor.querySelectorAll('.inline-image')
        )
          .filter(
            (el) =>
              el.tagName.toLowerCase() === 'img' || el.querySelector('img')
          )
          .map((el) =>
            this.inlineImages.findIndex(
              (inlineImage) => inlineImage.id === el.id
            )
          );
        const removedInlineImages = this.inlineImages.filter(
          (inlineImage, index) => !activeInlineImagesIndices.includes(index)
        );

        removedInlineImages.forEach((inlineImage) => {
          const attachment =
            inlineImage.src === inlineImage.attachment.objectUrl
              ? this.inlineAttachments.find(
                  ({ objectUrl }) =>
                    objectUrl === inlineImage.attachment.objectUrl
                )
              : this.inlineAttachments.find(
                  ({ id }) => id === inlineImage.attachment.id
                );
          if (attachment) {
            this.$emit('inlineImageRemoved', attachment);
          }
          this.removeInlineImage(inlineImage);
        });

        this.content = this.editor.getHTML();
        this.$emit('change', this.content);
      },
      setActiveTab(name) {
        this.activeTab = name;
      },
      onPathChange(e) {
        const path = e.path;
        const hasFormat = (prop) =>
          path === '(selection)' && this.editor.hasFormat(prop);
        const hasSelector = (re) => re.test(path);

        this.isBold = hasFormat('b') || hasSelector(/>B\b/);
        this.isItalic = hasFormat('i') || hasSelector(/>I\b/);
        this.isUnderlined = hasFormat('u') || hasSelector(/>U\b/);
        this.isOrderedList = hasFormat('ol') || hasSelector(/OL\b>/);
        this.isUnorderedList = hasFormat('ul') || hasSelector(/UL>\b/);
        this.isLink = hasFormat('a') || hasSelector(/>A\b/);
        this.isLeftAligned = /.align-left\b/.test(path);
        this.isCenterAligned = /.align-center\b/.test(path);
        this.isRightAligned = /.align-right\b/.test(path);
      },
      onShiftEnter(editor, ev, range) {
        ev.preventDefault();

        // Do not support shift-enter because it causes issues with copy-pasting text with linebreaks
        // copy the current event but set the shiftKey prop to false and trigger the normal enter keyHandler
        const event = new ev.constructor(ev.type, { ...ev, shiftKey: false });
        editor._keyHandlers.enter(editor, event, range);
      },
      setBold() {
        this.isBold = !this.isBold;
        this.isBold ? this.editor.bold() : this.editor.removeBold();
      },
      setItalic() {
        this.isItalic = !this.isItalic;
        this.isItalic ? this.editor.italic() : this.editor.removeItalic();
      },
      setUnderlined() {
        this.isUnderlined = !this.isUnderlined;
        this.isUnderlined
          ? this.editor.underline()
          : this.editor.removeUnderline();
      },
      setUnorderedlist() {
        this.isOrderedList = false;
        this.isUnorderedList = !this.isUnorderedList;
        this.isUnorderedList
          ? this.editor.makeUnorderedList()
          : this.editor.removeList();
      },
      setTextColour(colourRgbValue) {
        this.editor.setTextColour(colourRgbValue);
        this.textColour = colourRgbValue;
      },
      setFont(fontFamily) {
        this.editor.setFontFace(fontFamily);
        this.font = fontFamily;
      },
      setFontSize(fontSize) {
        this.editor.setFontSize(fontSize);
        this.fontSize = fontSize;
      },
      setOrderedList() {
        this.isUnorderedList = false;
        this.isOrderedList = !this.isOrderedList;
        this.isOrderedList
          ? this.editor.makeOrderedList()
          : this.editor.removeList();
      },
      toggleLink(modal) {
        return this.isLink
          ? this.editor.removeLink()
          : this.closeInsertLinkModal(modal);
      },
      insertLink(modal) {
        this.validateLinkUrl = true;

        if (!this.linkUrlIsValid) {
          return;
        }

        this.editor.makeLink(sanitizeUrl(this.linkUrl), {
          target: '_blank',
          rel: 'noopener noreferrer',
        });
        this.closeInsertLinkModal(modal);
      },
      closeInsertLinkModal(modal) {
        modal.toggle();
        this.linkUrl = '';
        this.validateLinkUrl = false;
      },
      setTextAlignment(value) {
        this.textAlignment = value;
        this.editor.setTextAlignment(this.textAlignment);
      },
      onInlineImageInserted({ file, objectUrl }) {
        const inlineImage = this.createInlineImage(objectUrl);
        this.editor.insertElement(inlineImage.$el);
        this.$emit('inlineImageInserted', { file, objectUrl });
      },
      createInlineImage(src, attachment) {
        const inlineImage = new InlineImageClass({
          propsData: { src, attachment },
        });
        inlineImage.$mount();
        this.inlineImages.push(inlineImage);
        return inlineImage;
      },
      removeInlineImage(inlineImage) {
        const index = this.inlineImages.findIndex(
          ({ src, attachment }) =>
            src === inlineImage.src &&
            attachment.id === inlineImage.attachment.id
        );
        if (inlineImage.$el.parentNode) {
          inlineImage.$el.parentNode.removeChild(inlineImage.$el);
        }
        inlineImage.$destroy();
        this.$delete(this.inlineImages, index);
      },
    },
  };
</script>

<style src="./RichTextEditor.scss" lang="scss"></style>
