/** Best effort URL sanitization. */
export function sanitizeUrl(s: string): string | null {
  try {
    return new URL(s).toString();
  } catch {
    try {
      return new URL(`https://${s}`).toString(); // handle bare domains
    } catch {
      return null;
    }
  }
}
